.message-box {
    position: fixed;
    color: white;
    padding: 0px 20px;
    top: 20px;
    left: 0;
    right: 0;
    height: auto;
    width: 500px;
    border-radius: 5px;
    z-index: 10000 !important;
}

.message-box.error {
    border-color: #8d1d1d;
    background-color: #e33636;
}

.message-box.success {
    border-color: #28911a;
    background-color: #3cc72a;
}
